<template>
  <div>
    <van-image :src="require('@/assets/banner.jpg')"/>
    <van-tabs v-model="active" @click="tabClick">
      <van-tab title="全部" name="all"></van-tab>
      <van-tab title="未支付" name="pending"></van-tab>
      <van-tab title="已支付" name="shipping"></van-tab>
    </van-tabs>
    <van-list
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        style="margin-top: 10px"
    >
      <van-card
          v-for="order in orders"
          :key="order.orderNo"
          style="background-color: #f5f2f2"
      >
        <template #title>
          <p style="font-size: 14px">
            <van-icon name="location-o"/>
            {{ order.startAddress }}
            <span> 至 </span>
            <van-icon name="location"/>
            {{ order.endAddress }}
          </p>
        </template>
        <template #desc>
          <p style="font-size: 14px;margin-top: 10px">
            <van-icon name="underway-o"/>
            {{ order.startTime }}
          </p>
        </template>
        <template #price>
          <span style="font-size: 14px;color: #f40606">￥{{ order.payPrice }}</span>
          <span style="font-size: 14px">元</span>
        </template>
        <template #footer>
          <van-button v-if="order.payStatus === true" type="info" round size="normal" @click="info(order)">去查看</van-button>
          <van-button v-if="order.payStatus === false" type="info" round size="normal" @click="info(order)">去支付</van-button>
        </template>
      </van-card>
    </van-list>
  </div>
</template>
<script>
import * as orderApi from "@/api/order";

export default {
  name: "DrivelList",
  data() {
    return {
      active: 'all',
      orders: [],
      page: 1,
      pageSize: 20,
      isPay: null,
      finished: false,
    };
  },
  methods: {
    tabClick() {
      this.page = 1;
      this.pageSize = 20;
      this.orders = [];
      this.onLoad();
    },
    async onLoad() {
      try {
        if (this.active === 'all') {
          this.isPay = null;
        } else if (this.active === 'pending') {
          this.isPay = false;
        } else if (this.active === 'shipping') {
          this.isPay = true;
        }
        let phone = localStorage.getItem('phone');
        if (!phone) {
          this.$router.replace({
            path: '/driveLogin'
          })
        }
        const response = await orderApi.orderList({
          pageNum: this.page,
          pageSize: this.pageSize,
          isPay: this.isPay,
          phone: phone,
        });
        if (response.data.length < this.pageSize) {
          this.finished = true;
        } else {
          this.page++;
        }
        this.orders = [...this.orders, ...response.data];
      } catch (error) {
        console.error(error);
      }
    },
    info(order) {
      this.$router.push({
        path: '/driveOrderInfo/' + order.orderNo
      })
    }
  },
};
</script>
<style lang="scss" scoped>

</style>
